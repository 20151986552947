import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, imgChristies, imgHollywood, imgGoogle, imgHS, imgNike, imgWU } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          {/* <Title title="Some clients I've worked with" /> */}
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1500} distance="30px">
          <Row>
            <Col className="hs" md={2} sm={6}>
              <ProjectImg alt={title} filename={imgHS} />
            </Col>
            <Col md={2} sm={6}>
              <ProjectImg alt={title} filename={imgChristies} />
            </Col>
            <Col className="nike" md={2} sm={6}>
              <ProjectImg alt={title} filename={imgNike} />
            </Col>
            <Col md={2} sm={6}>
              <ProjectImg alt={title} filename={imgGoogle} />
            </Col>
            <Col md={2} sm={6}>
              <ProjectImg alt={title} filename={imgHollywood} />
            </Col>
            <Col md={2} sm={6}>
              <ProjectImg alt={title} filename={imgWU} />
            </Col>
          </Row>
        </Fade>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
