import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Connor Black | Product & Marketing Expert', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'As an entrepreneur, creator, and executive—with 8+ years of experience in technology and business—I\'ve sold two startups, worked with clients such as Nike, Google, and Sean Parker, and worked with founding teams from seed-funding through series-funding to help make their visions a reality.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Connor Black',
  subtitle: 'I build, grow, and scale products for innovative companies.',
  cta: 'Let\'s Talk',
  email: 'mailto:connor@connorblack.io',
  imgChristies: 'christies-logo.png',
  imgHollywood: 'hollywood-reporter-logo.png',
  imgGoogle: 'google-logo.png',
  imgHS: 'henry-schein-logo.png',
  imgNike: 'nike-logo.png',
  imgWU: 'waking-up-logo.png',
};

// ABOUT DATA
export const aboutData = {
  img: 'headshot.png',
  paragraphOne: 'As an entrepreneur, creator, and executive—with 8+ years of experience in technology and business—I\'ve sold two startups, worked with clients such as Nike, Google, and Sean Parker, and worked with founding teams from seed-funding through series-funding to help make their visions a reality.',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'mailto:connor@connorblack.io',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/connorblack/',
    },
    {
      id: uuidv1(),
      name: 'medium',
      url: 'https://medium.com/@connorjblack',
    },
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/connorblack_',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
